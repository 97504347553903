// import Cookies from 'js-cookie';

export default {
    init() {
        // JavaScript to be fired on all pages

        // Jquery-match-height
        $(function () {
            $('.item__inner').matchHeight();
            $('.item__content').matchHeight();
            // $('.item__title').matchHeight();
        });

        $(window).resize(function () {
            $('.item__inner').matchHeight();
            $('.item__content').matchHeight();
            // $('.item__title').matchHeight();
        });


        // var $grid = $('.grid');
        // $grid.imagesLoaded(function(){
        //     $grid.masonry({
        //         // options...
        //         itemSelector: '.grid-item',
        //     });

        //     //then also call it after ajax event
        //     $(document).on('sf:ajaxfinish', '.searchandfilter', function () {
        //         $grid.masonry('reloadItems');
        //     });
        // });

        jQuery(document).imagesLoaded(function () {

            var $container = jQuery('.grid');

            $container.imagesLoaded(function () {
                jQuery('.grid').show();

                $container.masonry({
                    itemSelector: '.grid-item',
                    isAnimated: false,
                });
            });


            $(document).on('sf:ajaxfinish', '.searchandfilter', function () {
                $container.masonry('reloadItems');
                $container.masonry('layout');
            });
        });


        // Slide away opening hours on hover menu item with dropdown.
        jQuery(document).ready(function ($) {
            $('.no-touchevents .nav-primary > ul > li.menu-item-has-children').hover(function () {
                $('.col__header__opening__hours').toggleClass('col__header__opening__hours--slide');
            });
        });

        jQuery(document).ready(function ($) {
            museaFilter();

            $(document).ajaxSuccess(function () {
                museaFilter();

                $(function () {
                    $('.item').matchHeight();
                    $('.item__content').matchHeight();
                    // $('.item__title').matchHeight();
                });
            });

            function museaFilter() {
                $('.sf-input-checkbox').each(function () {
                    let val = $(this).val();
                    $(this).parent().addClass(val);
                });
            }
        });

        $(document).ready(function () {
            $('.fancybox').fancybox({
                scrolling: 'hidden',
                helpers: {
                    overlay: {
                        locked: true,
                    },
                },
            });

            $('.row--passed').infiniteScroll({
                // options
                path: '.overview__pagination .next',
                append: '.item--passed ',
                history: false,
                hideNav: '.overview__pagination',
                scrollThreshold: false,
                button: '.btn-loadmore',
            });

            /* eslint-disable */
            $('.row--passed').on('append.infiniteScroll', function (event, response, path, items) {
                $('.item__content').matchHeight();
            });
            /* eslint-enable */
        });

        $(document).on('click', 'a[href^="#"]', function (event) {
            event.preventDefault();

            $('html, body').animate({scrollTop: $($.attr(this, 'href')).offset().top - 220}, 500);
        });
    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        /* eslint-disable */

        // $('.nieuwsbrief__close, .gform_button').on('click', function () {
        //     Cookies.set('popup', 'true', { expires: 7 });
        // });
        //
        // let myCookie = Cookies.get('popup');
        //
        // if (!myCookie) {
        //     setTimeout(function() {
        //         $.fancybox.open({
        //             src: '#nieuwsbrief',
        //             modal: true
        //         });
        //     }, 12500);
        // }

        /* eslint-enable */
    },
};
