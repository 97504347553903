$(document).ready(function() {

    // Variable
    var primaryMenu = $('.nav-primary .nav');
    var menuIcon = $('.menu__icon')
    var primaryOverlayTouch = $('.nav-primary__overlay');
    var touchEvents = $('.touchevents');
    var body = $('body')

    // Variable: primaryMenu
    // Add icon angle down to element with dropdown menu
    // primaryMenu.find('> li.menu-item-has-children > a').append('<i class="fal fa-angle-down"></i>');
    // primaryMenu.find('> li ul.sub-menu > li.menu-item-has-children > a').append('<i class="fal fa-angle-right"></i>');

    // Add class sub-menu1, sub-menu2 and submenu-3 to first, second and third dropdown menu
    primaryMenu.find('> li > ul.sub-menu').addClass('sub-menu1');
    primaryMenu.find('> li > ul.sub-menu > li > ul.sub-menu').addClass('sub-menu2');
    primaryMenu.find('> li > ul.sub-menu > li > ul.sub-menu li > ul.sub-menu').addClass('sub-menu3');

    // Variable: touchEvents
    touchEvents.find('.nav-primary .nav .menu-item-has-children').append('<div class="menu-item-has-children__overlay"></div>');
    touchEvents.find('.menu-item-has-children__overlay').on('click', function(){
        $(this).parent('.menu-item-has-children').addClass('menu-item-has-children__open');
    });

    // Variable: primaryOverlayTouch
    primaryOverlayTouch.on('click', function(){
        menuIcon.removeClass('open');
        body.removeClass('menu__open');
    });

    // Variable: menuIcon
    menuIcon.on('click', function(){
        $(this).toggleClass('open');
        body.toggleClass('menu__open');
    });

    $('.col__header__opening__hours__search').on('click',function(){
        var clicks = $(this).data('clicks');
        if (clicks) {
            $('.header__low').toggleClass('header__low--toggle');
            $('#opening__hours__clock').toggleClass('opening__hours__clock--toggle');
            $('#opening__hours__search').toggleClass('opening__hours__search--toggle');
         } else {
            $('.header__low').toggleClass('header__low--toggle');
            $('.search-field').select();
            $('#opening__hours__clock').toggleClass('opening__hours__clock--toggle');
            $('#opening__hours__search').toggleClass('opening__hours__search--toggle');
         }
        $(this).data('clicks', !clicks);
    });
});
