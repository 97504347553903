// import external dependencies
import 'jquery';

// Import everything from autoload
import './autoload/_bootstrap.js'

import 'custom-event-polyfill/polyfill';
import 'js-cookie/src/js.cookie';
import '@fancyapps/fancybox/dist/jquery.fancybox';
import 'masonry-layout/dist/masonry.pkgd.min';
import 'infinite-scroll/dist/infinite-scroll.pkgd.min';
import 'slick-carousel/slick/slick.min';
import 'jquery-match-height/jquery.matchHeight';

// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import modernizr from './routes/modernizr';
import menu from './routes/menu';
import home from './routes/home';
import nieuws from './routes/nieuws';
import templateContact from './routes/templateContact';
import pageRouteToegankelijkheidData from './routes/templateContact';

/** Populate Router instance with DOM routes */
const routes = new Router({
    // All pages
    common,
    modernizr,
    menu,
    // Home page
    home,
    // Home page
    nieuws,
    templateContact,
    pageRouteToegankelijkheidData,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());
