/* eslint-disable */

jQuery(document).ready(function($) {

//     // init Isotope
//     var $container = $('.checkbox__filter').isotope({
//         itemSelector: '.checkbox__filter__item'
//     });
  
  
//   // filter with selects and checkboxes
//   var $checkboxes = $('.filter__checkbox__button input');
  
//   $checkboxes.change( function() {
//     // map input values to an array
//     var inclusives = [];
//     // inclusive filters from checkboxes
//     $checkboxes.each( function( i, elem ) {
//       // if checkbox, use value if checked
//       if ( elem.checked ) {
//         location.hash = "filter=" + encodeURIComponent( elem.value );
//         inclusives.push( elem.value );
//       }
//     });
  
//     // combine inclusive filters
//     var filterValue = inclusives.length ? inclusives.join(', ') : '*';
  
//     $container.isotope({ filter: filterValue })
//   });

//     // Function to decode the hash
//     function decodeHash() {
//         var $matches = location.hash.match( /filter=([^&]+)/i );
//         var $hashFilter = $matches && $matches[1];

//         return $hashFilter && decodeURIComponent( $hashFilter );
//     }

//     // On window hash change
//     function windowHashChange() {
//         var $hashFilter = decodeHash();

//         // Filter
//         $notes.isotope( {
//             filter: $hashFilter
//         } );

//     }

//     $( window ).on( "hashchange", windowHashChange );

//     // Load the function to initialize Isotope
//     windowHashChange();
});

/* eslint-enable */
