export default {
    init() {
        // JavaScript to be fired on the home page
    },
    finalize() {
        // JavaScript to be fired on the home page, after the init JS

        function bannerSwitch(){ 
            $('.banner__block--stedelijk').hover(function () {
                $('.banner__block--henriette').stop().toggleClass('banner__block--henriette--active');
                $('.banner__block--stedelijk').stop().toggleClass('banner__block--stedelijk--active');
            }, 
            function () {
                $('.banner__block--henriette').stop().toggleClass('banner__block--henriette--active');
                $('.banner__block--stedelijk').stop().toggleClass('banner__block--stedelijk--active');
            }); 

            $('.banner__block--henriette').hover(function () {
                $('.banner__block--henriette').stop().toggleClass('banner__block--henriette--inactive');
                $('.banner__block--stedelijk').stop().toggleClass('banner__block--stedelijk--inactive');
            }, 
            function () {
                $('.banner__block--henriette').stop().toggleClass('banner__block--henriette--inactive');
                $('.banner__block--stedelijk').stop().toggleClass('banner__block--stedelijk--inactive');
            });
        }
        bannerSwitch();

        $('.collection__slider').slick({
            dots: false,
            infinite: true,
            arrows: false,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 3,
            pauseOnHover: false,
            autoplay: true,
            swipeToSlide: true,
            autoplaySpeed: 3500,
            responsive: [
                {
                    breakpoint: 650,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    },
                },
            ],
        });

        $('.tentoonstellingen__slider').slick({
            dots: false,
            infinite: false,
            arrows: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            pauseOnHover: false,
            autoplay: true,
            autoplaySpeed: 3500,
            swipeToSlide: true,
            responsive: [
                {
                    breakpoint: 9999,
                    settings: 'unslick',
                },
                {
                    breakpoint: 575,
                     settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: false,
                    },
                },
            ],
        });

        $('.activiteiten__slider').slick({
            dots: false,
            infinite: true,
            arrows: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            pauseOnHover: false,
            autoplay: true,
            autoplaySpeed: 3500,
            swipeToSlide: true,
            responsive: [
                {
                    breakpoint: 9999,
                    settings: 'unslick',
                },
                {
                    breakpoint: 575,
                     settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: false,
                    },
                },
            ],
        });
    },
};
